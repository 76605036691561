var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"activity-data"},[_c('p',{staticClass:"info-header"},[_vm._v(" This is where you’ll see job posts that have either been sent to you as a result of a match, or jobs that you’ve found and applied to on your own. For any new job post sent to you, you can choose to apply or decline. Once you’ve applied to a job, you can track its status on this page. Any declined jobs will be removed from the table. ")]),_c('div',{staticClass:"header"},[_c('h2',{staticClass:"heading"},[_vm._v("Activity")]),_c('div',{staticClass:"filter-switch"},[_c('span',{class:['filter', _vm.active ? 'active' : ''],on:{"click":function($event){_vm.active = !_vm.active}}},[_vm._v("Active")]),_c('span',{class:['filter', !_vm.active ? 'active' : ''],on:{"click":function($event){_vm.active = !_vm.active}}},[_vm._v("Inactive")])])]),(_vm.jobs.length)?_c('table',{staticClass:"table table-striped table-hover activities-table jobs-table"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column},[_vm._v(_vm._s(column))])}),0)]),_c('tbody',_vm._l((_vm.list),function(item,index){return _c('tr',{key:index},[_c('td',[(
                item.company[0].profilePhoto &&
                typeof item.company[0].profilePhoto === 'object'
              )?_c('signed-image',{attrs:{"file":item.company[0].profilePhoto,"alt":item.company[0].name}}):_c('img',{attrs:{"src":"/img/icons/default-company.svg","alt":""}}),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'profile-id',
                  params: {
                    profileId: item.company[0]._id,
                    type: 'provider',
                  },
                })}}},[_vm._v(" "+_vm._s(item.company[0].name)+" ")])],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"status"},[(item.type == 'job-application')?_c('span',{class:_vm.hasProfileViewAccess(item.company[0].provider)
                  ? 'pending-contact'
                  : 'applied'},[_vm._v(_vm._s(_vm.hasProfileViewAccess(item.company[0].provider) ? "Pending Contact" : "Applied"))]):(item.type == 'sent-job-post')?_c('span',{staticClass:"sent-job-post"},[_vm._v("Received Job Post")]):_c('span',{staticClass:"match"},[_vm._v("New Match")])]),_c('td',[_vm._v(_vm._s(_vm.getFormattedDate(item.createdAt)))]),_c('td',{staticClass:"action-data"},[_c('ActionButtonsDropdown',{attrs:{"actions":_vm.getActions(item),"btnText":'Manage Job',"id":item._id}})],1)])}),0)]):_vm._e(),_c('div',{staticClass:"activities-mobile mobile-cards-wrapper"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"activity-wrapper mobile-card"},[_vm._m(0,true),_c('div',{staticClass:"company-name",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'profile-id',
              params: { profileId: item.company[0]._id, type: 'provider' },
            })}}},[_vm._v(" "+_vm._s(item.company[0].name)+" ")]),_c('div',{staticClass:"role-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"dates-wrapper"},[_c('div',{staticClass:"status"},[(item.type == 'job-application')?_c('span',{class:_vm.hasProfileViewAccess(item.company[0].provider)
                  ? 'pending-contact'
                  : 'applied'},[_vm._v(_vm._s(_vm.hasProfileViewAccess(item.company[0].provider) ? "Pending Contact" : "Applied"))]):(item.type == 'sent-job-post')?_c('span',{staticClass:"sent-job-post"},[_vm._v("Received Job Post")]):_c('span',{staticClass:"match"},[_vm._v("New Match")])]),_c('div',{staticClass:"date"},[_c('span',{staticClass:"date-header"},[_vm._v("Date")]),_vm._v(" "+_vm._s(_vm.getFormattedDate(item.createdAt))+" ")])]),_c('div',{staticClass:"btns-wrapper"},[_c('div',{staticClass:"btn view-btn"},[_c('a',{on:{"click":function($event){return _vm.$router.push(("/jobs/" + (item._id)))}}},[_vm._v("View Job")])]),(!_vm.appliedJobs.includes(item._id))?_c('div',{staticClass:"btn"},[_c('a',{on:{"click":function($event){return _vm.handleApplyForJob(item._id, item.company[0]._id)}}},[_vm._v("Apply")])]):_vm._e()])])}),0),(_vm.limit < _vm.total)?_c('div',{staticClass:"pagination"},[_c('b-pagination',{attrs:{"total-rows":_vm.total,"per-page":_vm.limit},on:{"click":_vm.goToPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]),_c('toast-message',{ref:"toastMessage"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"/img/icons/default-company.svg","alt":""}})])}]

export { render, staticRenderFns }