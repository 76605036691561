<template>
  <div class="card" v-if="project" @click="goToProjectDetails">
    <div class="card-header">
      <div class="left">
        <img class="default-img" src="/img/icons/company.svg" alt="" />
        <div class="project-title title-wrapper">
          <span
            class="company-name"
            @click.stop="
              $router.push({
                name: 'profile-id',
                params: { profileId: project.profile[0]._id, type: 'provider' },
              })
            "
            >{{ project.profile[0].name }}</span
          >
          <span class="project-name heading">{{ project.name }}</span>
        </div>
      </div>
    </div>
    <div class="card-details">
      <div class="project-location">
        <PhMapPin weight="fill" size="32px" color="#E24646" /><span
          class="location"
          >{{ location || "" }}</span
        >
      </div>
    </div>
    <div class="description">
      {{
        project.description ? project.description : "No description provided."
      }}
    </div>
    <div class="industry" v-if="project.profile[0].industry">
      <div class="title">
        <img src="/img/icons/industry.svg" alt="" />Industry
      </div>
      <div class="title">{{ project.profile[0].industry[0] }}...</div>
    </div>
    <span class="posted"> Posted {{ postDate }} </span>
  </div>
</template>

<script>
import { PhMapPin } from "phosphor-vue"
import moment from "moment"

export default {
  props: {
    project: Object,
  },
  components: {
    PhMapPin,
  },
  data() {
    return {
      location: "",
      postDate: "",
    }
  },
  mounted() {
    this.location = this.project.profile[0].city
      ? this.project.profile[0].city + ", " + this.project.profile[0].state
      : this.project.profile[0].state
    this.postDate = moment(this.project.profile[0].createdAt).fromNow()
  },
  methods: {
    goToProjectDetails() {
      this.$router.push("/trade-partners/projects/" + this.project._id)
    },
  },
}
</script>

<style scoped>
.posted {
  position: unset;
}
.title-wrapper {
  align-items: flex-start !important;
}
</style>
