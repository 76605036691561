import axios from "@/utils/api-v1"

export default {
  createProfile(userId, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/profile`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  async getProfile(profileId) {
    return await axios.get(`/api/profile/${profileId}`)
  },
  async getRequestedProfiles() {
    return await axios.get(`/api/profile/access/requested`)
  },
  async getGrantedProfiles() {
    return await axios.get(`/api/profile/access/granted`)
  },
  async getRejectedProfiles() {
    return await axios.get(`/api/profile/access/rejected`)
  },
  checkUserProfileExists(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/profile/check/${userId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  async getProfileCompleted(profileId) {
    return await axios.get(`/api/profile/completed/${profileId}`)
  },
  async getUserProfile(userId) {
    return await axios.get(`/api/profile/user/${userId}`)
  },
  async applyForProject(projectProfileId, profileId, projectId) {
    return await axios.patch(
      `/api/profile/access/request/${projectProfileId}`,
      { profile: profileId, project: projectId, type: "project" }
    )
  },
  async requestProfileAccess(recipientProfileId, id, type) {
    let body = { type }
    if (type == "project") {
      body.project = id
    } else if (type == "job" || type == "job-application") {
      body.job = id
    }
    return await axios.patch(
      `/api/profile/access/request/${recipientProfileId}`,
      body
    )
  },
  async grantProfileAccess(recipientProfileId, id, type) {
    let body = { type }
    if (type == "project") {
      body.project = id
    } else if (type == "job" || type == "job-application") {
      body.job = id
    }
    return await axios.patch(
      `/api/profile/access/grant/${recipientProfileId}`,
      body
    )
  },
  async grantProject(projectProfileId, profileId, projectId) {
    return await axios.patch(`/api/profile/access/grant/${projectProfileId}`, {
      profile: profileId,
      project: projectId,
      type: "project",
    })
  },
  async rejectProfileAccess(recipientProfileId, id, type, reason) {
    let body = { type, reason }
    if (type == "project") {
      body.project = id
    } else if (type == "job") {
      body.job = id
    }
    return await axios.patch(
      `/api/profile/access/reject/${recipientProfileId}`,
      body
    )
  },
  async sendSupport(profileId, data) {
    return await axios.post(`/api/profile/support/`, {
      profileId,
      data,
    })
  },
  updateProfile(profileId, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/profile/${profileId}`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  findUsers(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/find-users-within-radius`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  searchUsersByParams(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/profile/search`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  sendJobBriefing(company, jobBriefing, userId) {
    return axios.post("/api/profile/" + userId + "/briefing", {
      company,
      jobBriefing,
      userId,
    })
  },
  getJobSeekers(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/admin/jobSeekers`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  getCompanies(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/admin/companies`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  async getOpportunities(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/profile/job-seeker/jobs-received`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  async getAppliedJobs(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/profile/job-seeker/job-applications`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  async getJobMatches(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/profile/job-seeker/job-matches`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
  async getSuccessCenterItems(id) {
    return await axios.get(`/api/profile/${id}/success-center-items`)
  },
  async getActivities(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/profile/job-seeker/activities`, data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log("error", error)
          if (error.response.data) {
            reject(error.response.data)
          } else {
            reject(error.message)
          }
        })
    })
  },
}
