<template>
  <div class="job-view" v-if="job">
    <div class="job-summary">
      <RedirectLink title='Back to "Find Employees"' :to="'/employees/jobs'" />
      <div class="job-info">
        <div class="job-basics">
          <span class="job-name">{{ job.name }}</span>
          <capsule-list :items="[job.type]" :highlighted="true" />
          <span class="amount">{{ job.compensation }}</span>
          <span class="posted"> Posted on {{ postDate }} </span>
        </div>
        <div class="count-details">
          <div class="count">
            <span class="stats">{{ viewCount }}</span>
            <span>Views</span>
          </div>
          <div class="count">
            <span class="stats">{{ applicantCount }}</span>
            <span>Applicants</span>
          </div>
          <div class="count">
            <span class="stats">{{ declineCount }}</span>
            <span>Declined</span>
          </div>
        </div>
        <div
          class="edit-btn"
          @click="$router.push(`/employees/jobs/${job._id}/edit`)"
        >
          Edit Job
        </div>
      </div>
      <div class="description-wrapper">
        <div class="description">
          {{ job.description ? job.description : "No description provided." }}
        </div>
        <span class="view" @click="$router.push(`/employees/jobs/${job._id}`)"
          >View full job post</span
        >
      </div>
      <div class="switch">
        <div
          :class="['tab', { active: matchesView == false }]"
          @click="matchesView = !matchesView"
        >
          Applicants
        </div>
        <div
          :class="['tab', { active: matchesView == true }]"
          @click="matchesView = !matchesView"
        >
          Matches for this job
        </div>
      </div>
      <table
        class="table table-striped table-hover activities-table activities-list"
      >
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-if="
              (matchesView && matches.length > 0) ||
              (!matchesView && job.applicants.length > 0)
            "
          >
            <tr
              v-for="(item, index) in matchesView ? matches : job.applicants"
              :key="index"
            >
              <td class="name-column">
                <signed-image
                  v-if="
                    item.profilePhoto &&
                    typeof item.profile.profilePhoto === 'object'
                  "
                  :file="item.profile.Photo"
                  :alt="item.profile.name"
                />
                <img v-else src="/img/icons/jobseeker.svg" alt="" />
                <div class="name">
                  <template v-if="item.hasProfileViewAccess">
                    {{
                      matchesView
                        ? `${item.name} ${item.lastName}`
                        : `${item.profile.name} ${item.profile.lastName}`
                    }}
                  </template>
                  <template v-else>
                    {{
                      matchesView
                        ? `Jobseeker ${item._id.slice(0, 4)}`
                        : `Jobseeker ${item.profile._id.slice(0, 4)}`
                    }}
                  </template>
                  <span v-if="!matchesView && isMatch(item.profile._id)"
                    >Matched job seeker</span
                  >
                </div>
              </td>
              <td>
                {{
                  lastActivityString[item.latestActivity] +
                  item.latestActivityDate
                }}
              </td>
              <td v-if="matchesView">
                {{
                  item.latestActivityDate
                    ? getExpirationDate(item.latestActivityDate)
                    : ""
                }}
              </td>
              <td class="actions">
                <div class="action-btns">
                  <default-button
                    :labelText="'View'"
                    @click="
                      $router.push({
                        path:
                          '/jobs/' +
                          job._id +
                          '/matches/' +
                          (matchesView ? item._id : item.profile._id),
                        query: { isMatch: matchesView },
                      })
                    "
                  />
                  <default-button
                    :labelText="'Decline'"
                    @click="
                      handleDeclineModal(
                        matchesView ? item._id : item.profile._id
                      )
                    "
                  />
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="4" class="no-data-message">
                {{ matchesView ? "No matches found." : "No applicants found." }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <!---mobile-view--->
      <div class="activities-list-mobile mobile-cards-wrapper">
        <template
          v-if="
            (matchesView && matches.length > 0) ||
            (!matchesView && job.applicants.length > 0)
          "
        >
          <div
            v-for="(item, index) in matchesView ? matches : job.applicants"
            :key="index"
            class="job-wrapper mobile-card"
          >
            <div class="company-name">
              {{ matchesView ? item.name : item.profile.name }}
            </div>
            <div class="dates-wrapper" style="justify-content: center">
              <div class="date text-center">
                <span class="date-header">Last Activity</span>
                {{
                  lastActivityString[item.latestActivity] +
                  item.latestActivityDate
                }}
              </div>
            </div>
            <div
              class="dates-wrapper"
              style="margin-top: 24px; justify-content: center"
              v-if="matchesView"
            >
              <div class="date text-center">
                <span class="date-header">Expiration Date</span>
                {{
                  item.latestActivityDate
                    ? getExpirationDate(item.latestActivityDate)
                    : ""
                }}
              </div>
            </div>
            <div class="btns-wrapper">
              <div class="btn">
                <a
                  @click="
                    $router.push(
                      '/jobs/' +
                        job._id +
                        '/matches/' +
                        (matchesView ? item._id : item.profile._id)
                    )
                  "
                  >View</a
                >
              </div>
              <div class="btn">
                <a
                  @click="
                    handleDeclineModal(
                      matchesView ? item._id : item.profile._id
                    )
                  "
                  >Decline</a
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-data-message">
            {{ matchesView ? "No matches found." : "No applicants found." }}
          </div>
        </template>
      </div>
      <!---mobile-view ends--->
    </div>
    <toast-message ref="toastMessage" />
    <default-modal
      :description="`Are you sure you want to decline this ${
        matchesView ? 'match' : 'applicant'
      }?`"
      :title="`Decline ${matchesView ? 'Match' : 'Applicant'}`"
      @onConfirm="handleEmployeeDecline(declineId)"
      id="decline-applicant-modal"
      :showCancel="true"
      :btnClasses="['delete-btn']"
    />
  </div>
</template>

<script>
import moment from "moment"
import job from "@/api/jobs"
import company from "@/api/company"
import RedirectLink from "@/components/RedirectLink"

export default {
  components: { RedirectLink },
  data() {
    return {
      job: null,
      postDate: "",
      viewCount: 0,
      applicantCount: 0,
      declineCount: 0,
      matchesView: false,
      lastActivityString: {
        profileRequestedAt: "Applied on ",
        unlockedAt: "Applicant unlocked on ",
        sentJobAt: "Job Sent on ",
        profileRejectedAt: "Applicant rejected on ",
        profileAcceptedAt: "Applicant accepted on ",
        matchFoundAt: "Matched on ",
      },
      declineId: null,
      matches: [],
    }
  },
  async created() {
    this.dataRetrieving = true
    await this.getJob()
    this.dataRetrieving = false
  },
  computed: {
    columns() {
      return this.matchesView
        ? ["Job Seeker", "Last Activity", "Expiration Date", "Actions"]
        : ["Job Seeker", "Last Activity", "Actions"]
    },
  },
  methods: {
    async getJob() {
      const jobId = this.$router.currentRoute.params?.jobId
      if (jobId) {
        const { data } = await job.getJobDetails(jobId)
        if (!data || data.errors) {
          this.showErrorMessage(
            data.errors[1],
            "We couldn't find data for this job."
          )
        } else {
          this.job = data
          this.postDate = moment(this.job.createdAt).format("MMM DD, YYYY")
          this.getJobStats()
          this.getJobViews()
          this.getLatestActivityOfMatch()
        }
      }
    },
    async getLatestActivityOfMatch() {
      let job = { ...this.job }
      const declinedRequests = this.$store.state.userProfile.profilesRejected

      // Filter matches to remove declined matches
      const filteredMatches = job.matches.filter((match) => {
        return !declinedRequests.some(
          (declined) =>
            declined.profile === match._id &&
            declined.type === "job-application" &&
            declined.job === job._id
        )
      })

      const unlockedApplicants =
        this.$store.state.userProfile?.provider.unlockedApplicants || []

      // Fetch and update latest activity for each applicant
      for (let applicant of filteredMatches) {
        const data = await this.$store.dispatch("api/getJobseekerActions", {
          profileId: applicant._id,
          jobId: this.job._id,
        })

        let actions = data.data.actions
        let latestAction = this.findLatestDateWithKey(actions, true)
        applicant.latestActivity = latestAction.key
        applicant.latestActivityDate = moment(latestAction.date).format(
          "MMM DD, YYYY [at] h:mm A"
        )

        let isUnlockedProfile = unlockedApplicants.find(
          (item) => item.profile === applicant._id
        )

        applicant.hasProfileViewAccess = !isUnlockedProfile ? false : true
      }

      this.job = { ...this.job, matches: filteredMatches }
      this.matches = filteredMatches
    },
    getJobViews() {
      let payload = {
        jobId: this.job._id,
        activityType: "view-job-details",
      }
      this.$store.dispatch("api/getActivityLogs", payload).then((response) => {
        let views = response.data
        if (views.length) {
          views = views.filter(
            (v, i, a) =>
              a.findIndex((t) => t.jobseekerId == v.jobseekerId) === i
          )
        }
        this.viewCount = views.length
      })
    },
    isMatch(id) {
      let matches = this.job.matches
      let match = matches.filter((match) => {
        return match._id == id
      })
      if (match.length) {
        return true
      }

      return false
    },
    getExpirationDate(activityDate) {
      if (this.$store.state.expiration_hours == 0) {
        return "No expiration"
      }

      let daysToAdd = this.$store.state.expiration_hours / 24
      let expirationDate = moment(activityDate, "MMM DD, YYYY [at] h:mm A")

      if (!expirationDate.isValid()) {
        console.error("Invalid activity date:", activityDate)
        return null
      }

      expirationDate = expirationDate.add(daysToAdd, "days")

      // Get the current date
      let currentDate = moment()

      // Compare expiration date with the current date
      if (expirationDate.isBefore(currentDate, "minute")) {
        return "Expired"
      }

      return expirationDate.format("MMM DD, YYYY [at] h:mm A")
    },
    async getJobStats() {
      let requests = this.$store.state.userProfile.profilesGranted
      let j = this.job
      for (let r of requests) {
        if (r.type == "job-application" && r.job == j._id && r.profile) {
          if (j.applicants) {
            j.applicants.push(r)
          } else {
            j.applicants = [r]
          }
        }
      }

      requests = this.$store.state.userProfile.profilesRequested
      for (let r of requests) {
        if (r.type == "job-application" && r.job == j._id && r.profile) {
          if (j.applicants) {
            j.applicants.push(r)
          } else {
            j.applicants = [r]
          }
        }
      }

      requests = this.$store.state.userProfile.profilesRejected
      for (let r of requests) {
        if (r.type == "job-application" && r.job == j._id && r.profile) {
          if (j.declined) {
            j.declined.push(r)
          } else {
            j.declined = [r]
          }
        }
      }

      //remove duplicates
      if (j.applicants) {
        j.applicants = j.applicants.filter(
          (v, i, a) => a.findIndex((t) => t.profile == v.profile) === i
        )
      }

      if (j.declined) {
        j.declined = j.declined.filter(
          (v, i, a) => a.findIndex((t) => t.profile == v.profile) === i
        )
      }

      const unlockedApplicants =
        this.$store.state.userProfile?.provider.unlockedApplicants || []

      for (let applicant of j.applicants) {
        const data = await this.$store.dispatch("api/getProfile", {
          profileId: applicant.profile,
        })
        applicant.profile = data.data

        const actions = await this.$store.dispatch("api/getJobseekerActions", {
          profileId: applicant.profile._id,
          jobId: j._id,
        })
        let latestAction = this.findLatestDateWithKey(actions.data.actions)
        applicant.latestActivity = latestAction.key
        applicant.latestActivityDate = moment(latestAction.date).format(
          "MMM DD, YYYY [at] h:mm A"
        )

        let isUnlockedProfile = unlockedApplicants.find(
          (item) => item.profile === applicant.profile._id
        )

        applicant.hasProfileViewAccess = !isUnlockedProfile ? false : true
      }

      this.applicantCount = j.applicants ? j.applicants.length : 0
      this.declineCount = j.declined ? j.declined.length : 0

      this.job = j
    },
    findLatestDateWithKey(obj, isMatch = false) {
      let latestKey = null
      let latestDate = new Date(-8640000000000000)

      for (const [key, value] of Object.entries(obj)) {
        if (isMatch) {
          if (key == "matchFoundAt" || key == "sentJobAt") {
            if (value) {
              const currentDate = new Date(value)
              if (currentDate > latestDate) {
                latestDate = currentDate
                latestKey = key
              }
            }
          }
        } else {
          if (value) {
            const currentDate = new Date(value)
            if (currentDate > latestDate) {
              latestDate = currentDate
              latestKey = key
            }
          }
        }
      }

      return { key: latestKey, date: latestDate.toISOString() }
    },
    async handleDeleteJob() {
      try {
        await job.deleteJob(this.$router.currentRoute.params?.jobId)
        this.$toast.success("Job has been deleted successfully")
        this.$router.push(`/employees/jobs`)
      } catch (error) {
        this.$toast.error(error.message || "We could not delete this job.")
      }
    },
    handleDeclineModal(id) {
      this.declineId = id
      this.$bvModal.show("decline-applicant-modal")
    },
    async handleEmployeeDecline(profileId) {
      const jobId = this.$router.currentRoute.params?.jobId
      const type = "job-application"

      try {
        await company.rejectProfileAccess(profileId, jobId, type)
        await this.$store.dispatch("api/getCompanyUserProfile", {
          userId: this.$store.state.user._id,
        })
        await this.getJob()
        this.declineId = null
        this.$bvModal.hide("decline-applicant-modal")
        this.$refs.toastMessage.showToast(
          "Application Declined successfully!",
          "success"
        )
      } catch (error) {
        this.$refs.toastMessage.showToast("Something went wrong!!", "error")
      }
    },
    setLoading(loading) {
      this.loading = loading
    },
    setDataRetrieving(isRetrieving) {
      this.dataRetrieving = isRetrieving
    },
  },
}
</script>

<style lang="scss" scoped>
.responsive-width {
  width: 80%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

ul.nav {
  display: none !important;
}

.description {
  color: var(--Gray-800, #3a4252);
  font-size: 16px;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-top: 24px;
  text-align: justify;
}

.view {
  color: var(--gfb-blue-brand-500, #0095e2);
  font-weight: 600;
  cursor: pointer;
}

.job-summary {
  padding: 24px 40px;
  background: #fff;
  display: flex;
  flex-direction: column;

  .job-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .job-basics {
    display: flex;
    flex-direction: column;
    .job-name {
      color: var(--Gray-900, #343946);
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .amount {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-weight: 600;
      margin: 24px 0;
    }

    .posted {
      color: var(--Gray-500, #677690);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .count-details {
    display: flex;
    min-width: 30%;
    justify-content: space-between;

    .count {
      display: flex;
      flex-direction: column;
      color: var(--Gray-600, #525e77);
      text-align: right;
      font-size: 16px;
      font-weight: 600;

      .stats {
        color: var(--Gray-800, #3a4252);
        font-size: 18px;
      }
    }
  }

  .edit-btn {
    display: flex;
    padding: 10px 24px 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 2px solid var(--gfb-blue-brand-500, #0095e2);
    background: var(--White, #fff);
    font-size: 14px;
    color: var(--gfb-blue-brand-500, #0095e2);
    font-weight: 600;
    cursor: pointer;
  }
}

table {
  background: var(--Gray-100, #eceef2);
  border: none;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid var(--Gray-200, #d5d9e2);

  thead {
    th {
      color: var(--Gray-900, #343946);
      font-size: 16px;
      letter-spacing: 0;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
      border-bottom: 1px solid var(--Gray-300, #b0b9c9);

      &:last-of-type {
        text-align: center;
      }
    }
  }

  tbody {
    display: block;
    max-height: calc(100vh - 250px);
    overflow: auto;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody tr {
    border-bottom: 1px solid var(--Gray-200, #d5d9e2);

    &:first-of-type {
      border-top: 1px solid var(--Gray-300, #b0b9c9);
    }

    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    td {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      white-space: normal;
      word-wrap: break-word;

      img {
        margin-right: 16px;
      }

      &.actions {
        text-align: center;

        .action-btns {
          display: flex;
          justify-content: center;
        }
      }

      &.name-column {
        display: flex;
        align-items: center;
        .name {
          font-weight: 600;
          display: flex;
          flex-direction: column;

          span {
            color: var(--Gray-500, #677690);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }

    &:last-child td:first-child {
      border-bottom-left-radius: 12px;
    }
    &:last-child td:last-child {
      border-bottom-right-radius: 12px;
    }

    &:first-child td {
      border-top-style: solid;
    }
  }

  tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  tbody tr:nth-of-type(even) {
    background-color: var(--Gray-50, #f6f7f9);
  }
}

.switch {
  display: flex;
  margin-top: 20px;
  border-top: 1px solid var(--Gray-200, #d5d9e2);

  .tab {
    width: 50%;
    text-align: center;
    color: var(--Gray-600, #525e77);
    font-size: 18px;
    font-weight: 600;
    padding: 24px;

    &.active {
      border-bottom: 2px solid var(--gfb-blue-brand-500, #0095e2);
      color: #0095e2;
    }
  }
}

.activities-list-mobile {
  display: none;
}

@media (max-width: 992px) {
  .activities-list {
    display: none;
  }

  .activities-list-mobile {
    display: flex;
    border: 1px solid var(--Gray-300, #b0b9c9);
    border-top: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 24px;
  }
}

.no-data-message {
  text-align: center;
  color: var(--Gray-500, #677690);
}
</style>
