<template>
  <div class="row provider-details" v-if="profile.provider">
    <RedirectLink />
    <div class="contact-info-wrapper">
      <div class="contact-info">
        <div class="profile-img">
          <signed-image
            class="profile-pic"
            v-if="profile.profilePhoto"
            :file="profile.profilePhoto"
            :alt="profile.name"
          />
          <img v-else src="/img/icons/mask-group.svg" alt="" />
        </div>
        <div class="contact-info-details">
          <h3>
            {{ profile.name }}
          </h3>
          <template v-if="!jobseekerCompanyView">
            <span
              ><PhUser weight="fill" size="24px" />{{
                `${profile.principleContact.firstName} ${profile.principleContact.lastName}`
              }}</span
            >
            <span
              ><PhMapPin weight="fill" size="24px" />{{ profile.city }},
              {{ profile.state }}</span
            >
            <span
              ><PhPhone weight="fill" size="24px" />{{
                profile.phoneNumber
              }}</span
            >
          </template>
        </div>
        <div
          class="contact-info-details second-column"
          v-if="!jobseekerCompanyView"
        >
          <span
            ><PhEnvelope weight="fill" size="24px" /> {{ profile.email }}</span
          >
          <span>
            <PhGlobe weight="fill" size="24px" />
            {{ profile.website }}
          </span>
        </div>
      </div>
      <!-- Todo: Add logic for connect with company -->
      <div class="warning" style="display: none">
        <div class="warning-text">Connect with company</div>
        <DefaultButton labelText="Connect" />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
import RedirectLink from "@/components/RedirectLink"
import { PhMapPin, PhPhone, PhEnvelope, PhUser, PhGlobe } from "phosphor-vue"

export default {
  components: {
    DefaultButton,
    PhMapPin,
    PhPhone,
    PhEnvelope,
    PhUser,
    PhGlobe,
    RedirectLink,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    applicant: {
      type: Boolean,
      default: false,
    },
    hasProfileViewAccess: {
      type: Boolean,
      default: false,
    },
    jobseekerCompanyView: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.provider-details {
  display: flex;
  padding: 24px 96px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--Gray-50, #f6f7f9);
  margin: 0;
  margin-bottom: 30px;

  .contact-info-wrapper {
    display: flex;
    width: 100%;

    .contact-info {
      display: flex;
      width: 100%;
      align-items: center;

      .profile-img {
        width: 104px;
        height: 104px;
        fill: var(--Gray-100, #eceef2);
        border-radius: 50%;
        margin-right: 32px;
        justify-content: center;
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
        }
      }

      .contact-info-details {
        display: flex;
        flex-direction: column;

        &.second-column {
          margin-left: 64px;
        }

        h3 {
          margin-bottom: 6px;
          font-size: 24px;
          font-weight: 700;
        }

        span {
          margin-top: 12px;
          font-size: 14px;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .warning {
    display: flex;
    padding: 20px 24px 24px 24px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Blue-50, #d6e1f3);
    background: var(--White, #fff);
    min-width: 25%;

    .warning-text {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-800, #3a4252);
      line-height: 140%;
    }

    .button-wrapper,
    .button-wrapper input {
      width: 100% !important;
    }
  }
}
</style>
