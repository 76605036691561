<template>
  <div class="container-fluid">
    <div class="activity-data">
      <p class="info-header">
        This is where you’ll see job posts that have either been sent to you as
        a result of a match, or jobs that you’ve found and applied to on your
        own. For any new job post sent to you, you can choose to apply or
        decline. Once you’ve applied to a job, you can track its status on this
        page. Any declined jobs will be removed from the table.
      </p>
      <div class="header">
        <h2 class="heading">Activity</h2>
        <div class="filter-switch">
          <span
            :class="['filter', active ? 'active' : '']"
            @click="active = !active"
            >Active</span
          >
          <span
            :class="['filter', !active ? 'active' : '']"
            @click="active = !active"
            >Inactive</span
          >
        </div>
      </div>
      <table
        class="table table-striped table-hover activities-table jobs-table"
        v-if="jobs.length"
      >
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td>
              <signed-image
                v-if="
                  item.company[0].profilePhoto &&
                  typeof item.company[0].profilePhoto === 'object'
                "
                :file="item.company[0].profilePhoto"
                :alt="item.company[0].name"
              />
              <img v-else src="/img/icons/default-company.svg" alt="" />
              <span
                style="cursor: pointer"
                @click.stop="
                  $router.push({
                    name: 'profile-id',
                    params: {
                      profileId: item.company[0]._id,
                      type: 'provider',
                    },
                  })
                "
              >
                {{ item.company[0].name }}
              </span>
            </td>
            <td>{{ item.name }}</td>
            <td class="status">
              <span
                v-if="item.type == 'job-application'"
                :class="
                  hasProfileViewAccess(item.company[0].provider)
                    ? 'pending-contact'
                    : 'applied'
                "
                >{{
                  hasProfileViewAccess(item.company[0].provider)
                    ? "Pending Contact"
                    : "Applied"
                }}</span
              >
              <span
                v-else-if="item.type == 'sent-job-post'"
                class="sent-job-post"
                >Received Job Post</span
              >
              <span v-else class="match">New Match</span>
            </td>
            <td>{{ getFormattedDate(item.createdAt) }}</td>
            <td class="action-data">
              <ActionButtonsDropdown
                :actions="getActions(item)"
                :btnText="'Manage Job'"
                :id="item._id"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <!--- mobile view --->
      <div class="activities-mobile mobile-cards-wrapper">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="activity-wrapper mobile-card"
        >
          <div class="img">
            <img src="/img/icons/default-company.svg" alt="" />
          </div>
          <div
            class="company-name"
            style="cursor: pointer"
            @click.stop="
              $router.push({
                name: 'profile-id',
                params: { profileId: item.company[0]._id, type: 'provider' },
              })
            "
          >
            {{ item.company[0].name }}
          </div>
          <div class="role-name">
            {{ item.name }}
          </div>
          <div class="dates-wrapper">
            <div class="status">
              <span
                v-if="item.type == 'job-application'"
                :class="
                  hasProfileViewAccess(item.company[0].provider)
                    ? 'pending-contact'
                    : 'applied'
                "
                >{{
                  hasProfileViewAccess(item.company[0].provider)
                    ? "Pending Contact"
                    : "Applied"
                }}</span
              >
              <span
                v-else-if="item.type == 'sent-job-post'"
                class="sent-job-post"
                >Received Job Post</span
              >
              <span v-else class="match">New Match</span>
            </div>
            <div class="date">
              <span class="date-header">Date</span>
              {{ getFormattedDate(item.createdAt) }}
            </div>
          </div>
          <div class="btns-wrapper">
            <div class="btn view-btn">
              <a @click="$router.push(`/jobs/${item._id}`)">View Job</a>
            </div>
            <div class="btn" v-if="!appliedJobs.includes(item._id)">
              <a @click="handleApplyForJob(item._id, item.company[0]._id)"
                >Apply</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--- mobile view end --->

      <div class="pagination" v-if="limit < total">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
    </div>
    <toast-message ref="toastMessage" />
  </div>
</template>

<script>
import moment from "moment"
import profile from "@/api/profile"
import ActionButtonsDropdown from "@/components/ActionButtonsDropdown"

export default {
  components: {
    ActionButtonsDropdown,
  },
  data() {
    return {
      jobs: [],
      columns: ["Company", "Role", "Status", "Date", "Actions"],
      currentPage: 1,
      limit: 20,
      total: 0,
      active: true,
      list: [],
      appliedJobs: [],
      savedJobsCount: 0,
      declinedJobs: [],
    }
  },
  methods: {
    getActions(item) {
      const actions = [
        { name: "View Job", function: () => this.viewJob(item._id) },
      ]

      // Conditionally add "Apply to Job" action
      if (!this.appliedJobs.includes(item._id)) {
        actions.push({
          name: "Apply to Job",
          function: () => this.handleApplyForJob(item._id, item.company[0]._id),
        })
      }

      // Conditionally add "Decline Job" action
      if (!this.declinedJobs.includes(item._id)) {
        actions.push({
          name: "Decline Job",
          function: () => this.handleDeclineJob(item._id, item.company[0]._id),
        })
      }

      return actions
    },
    async getDeclinedJobs() {
      const { data } = await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })

      const profilesRejected = data?.profilesRejected

      if (profilesRejected && profilesRejected.length) {
        const declinedJobIds = profilesRejected.map((item) => item.job)
        this.declinedJobs = declinedJobIds
      } else {
        this.declinedJobs = []
      }
    },
    async getActivities() {
      const activities = await this.$store.dispatch(
        "api/getJobseekerActivities",
        {
          limit: this.limit,
          page: this.currentPage,
        }
      )
      let jobs = activities.data.results

      this.jobs = jobs
      this.total = activities.data.total

      this.filterJobs()
    },
    filterJobs() {
      if (this.active) {
        this.list = this.jobs.filter(
          (job) => job.deletedAt == null || !job.deletedAt
        )
      } else {
        this.list = this.jobs.filter((job) => job.deletedAt)
      }
    },
    getFormattedDate(dateString) {
      const parsedDate = moment(dateString)

      const currentDate = moment()

      const diffInDays = currentDate.diff(parsedDate, "days")

      // Determine the display format based on the difference in days
      let displayDate
      if (diffInDays === 0) {
        displayDate = "Today at " + parsedDate.format("h:mm A")
      } else if (diffInDays === 1) {
        displayDate = "Yesterday at " + parsedDate.format("h:mm A")
      } else {
        displayDate = parsedDate.format("DD MMM YYYY [at] hh:mm A")
      }
      return displayDate
    },
    goToPage(page) {
      this.currentPage = page
    },
    viewJob(job) {
      this.$router.push(`/jobs/${job}`)
    },
    async handleDeclineJob(job, company) {
      try {
        let type = "job"
        await profile.rejectProfileAccess(company, job, type)
        this.getDeclinedJobs()

        //Show Toast success
        this.$refs.toastMessage.showToast(
          "Job declined successfully!!",
          "success"
        )
      } catch (error) {
        this.$refs.toastMessage.showToast(
          "Cannot decline job!! try again !!",
          "error"
        )
      }
    },
    async handleApplyForJob(job, company) {
      try {
        let type = "job-application"
        await profile.requestProfileAccess(company, job, type)
        this.getActivities()

        //Show Toast success
        this.$refs.toastMessage.showToast(
          "Job application has been sent successfully!!",
          "success"
        )
      } catch (error) {
        this.$refs.toastMessage.showToast(
          "Cannot send job application !! try again !!",
          "error"
        )
      }
    },
    hasProfileViewAccess(provider) {
      let profileId = this.$store.state.userProfile._id,
        unlockedApplicants = provider.unlockedApplicants || []

      let isUnlockedProfile = unlockedApplicants.find(
        (item) => item.profile === profileId
      )
      return !isUnlockedProfile ? false : true
    },
    async getSavedJobs() {
      const profileId = this.$store.state.userProfile?._id

      const data = await this.$store.dispatch("api/getSavedJobs", {
        limit: this.limit,
        page: this.currentPage,
        profileId,
      })

      this.savedJobsCount = data.data.total
    },
  },
  mounted() {
    this.getDeclinedJobs()
    this.getActivities()
    this.getSavedJobs()
  },
  watch: {
    active() {
      this.filterJobs()
    },
    total() {
      document.getElementById("activity-count").innerHTML = this.total
    },
    savedJobsCount() {
      document.getElementById("saved-jobs-count").innerHTML =
        this.savedJobsCount
    },
    currentPage() {
      this.getActivities()
    },
  },
}
</script>

<style scoped lang="scss">
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .activity-data {
    margin-left: 0;
    margin-right: 0;

    p.info-header {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-weight: 400;
    }

    .header {
      display: flex;
      padding: 24px 40px 16px 40px;
      align-items: center;
      gap: 48px;
      align-self: stretch;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      border: 1px solid var(--Gray-200, #d5d9e2);
      border-bottom: unset;
      width: 100%;
      background: #ffffff;

      .heading {
        color: var(--Gray-900, #343946);
        font-size: 20px;
        font-weight: 600;
        text-transform: none;
      }

      .filter-switch {
        display: flex;
        padding: 4px;
        align-items: center;
        border-radius: 8px;
        background: var(--Gray-100, #eceef2);

        .filter {
          display: flex;
          min-width: 140px;
          padding: 8px 48px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          color: var(--Gray-600, #525e77);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          cursor: pointer;

          &.active {
            background: #fff;
            color: var(--gfb-blue-brand-500, #0095e2);
          }

          @media (max-width: 576px) {
            min-width: 100px;
            padding: 8px 0;
          }
        }
      }
    }

    .popover-action {
      display: none;
      min-height: 52px;
      padding: 8px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--Gray-200, #d5d9e2);
      background: var(--White, #fff);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 65px;
      right: 90px;
      z-index: 999;

      ul {
        list-style: none;
        margin-bottom: 0;

        li {
          display: flex;
          padding: 8px;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          cursor: pointer;
          border-radius: 4px;
          margin-bottom: 2px;
          margin-top: 2px;

          &:hover,
          &:focus {
            background: var(--Gray-100, #eceef2);
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
          }

          //   &:last-of-type {
          //     border-top: 1px solid var(--Gray-300, #b0b9c9);
          //   }
        }
      }
    }

    .action-btn {
      cursor: pointer;
    }
  }
}

.activities-mobile {
  display: none;
}

@media (max-width: 992px) {
  .activities-table {
    display: none;
  }

  .activities-mobile {
    display: flex;
    border: 1px solid var(--Gray-200, #d5d9e2);
    padding: 24px;
    border-top: unset;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
</style>
