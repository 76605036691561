<template>
  <form method="POST" @submit.prevent="login">
    <div class="form-container">
      <div class="form-content-body pb-3">
        <h2 class="font-48-900 text-center dark-text mb-4">Sign In</h2>
        <!-- base-input -->
        <text-input label="Email" v-model="email"> </text-input>

        <!-- base-input -->
        <text-input label="Password" type="password" v-model="password">
        </text-input>

        <button
          class="btn btn-dark-white w-100 btn-form go-rounded-14 mb-4"
          :disabled="loading"
          type="submit"
        >
          Sign In
          <b-spinner small v-if="loading"></b-spinner>
        </button>

        <div class="d-md-flex align-items-center flex-column signin-controls">
          <div class="form-group form-check my-2">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              v-model="checkbox_remember_me"
            />
            <label class="form-check-label font-16-500" for="exampleCheck1"
              >Keep me logged in</label
            >
          </div>
          <router-link
            :to="{ name: 'resetpassword' }"
            class="font-16-500 dark-text my-2"
          >
            Forgot your password?
          </router-link>
        </div>
      </div>
      <router-link :to="{ name: 'signup' }">
        <div
          class="go-rounded yellow-bg-300 p-4 text-center m-2 orange-clr font-18-900"
        >
          Don’t have an account? <span class="d-block">Sign Up</span>
        </div>
      </router-link>
    </div>
    <div class="outside-copyright mt-4 text-center">
      <p class="font-16-500">
        By continuing you agree to GoFindBuild LLC.
        <span class="d-block text-center">
          <a href="/terms">Terms of Service</a> and
          <a href="/privacy-policy">Privacy Policy.</a>
        </span>
      </p>
    </div>
    <ToastMessage ref="toastMessage" />
  </form>
</template>
<script>
import { validateEmail } from "@/utils/validations"
import ToastMessage from "@/components/ToastMessage"

export default {
  components: {
    ToastMessage,
  },
  name: "login",
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      checkbox_remember_me: false,
    }
  },
  mounted() {
    setTimeout(() => {
      const recaptcha = this.$recaptchaInstance
      recaptcha.showBadge()
    }, 5000)
  },
  beforeDestroy() {
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  },
  computed: {
    isValid() {
      return validateEmail(this.email)
    },
  },
  methods: {
    login() {
      this.loading = true
      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
      }

      this.$store
        .dispatch("auth/login", payload)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.$refs.toastMessage.showToast(error.message, "error")
          console.log("login error", error.message)
        })
    },
  },
}
</script>
<style>
.flex-column {
  flex-direction: column;
}

@media (max-width: 768px) {
  .signin-controls {
    display: flex;
  }

  .form-content-body {
    padding: 20px !important;
  }
}
</style>
