<template>
  <div data-app>
    <div v-if="!paymentIntent">
      <table class="table table-striped table-hover activities-table">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in matches" :key="index">
            <td class="name-column">
              <signed-image
                v-if="
                  item.profilePhoto && typeof item.profilePhoto === 'object'
                "
                :file="item.Photo"
                :alt="item.name"
              />
              <img v-else src="/img/icons/jobseeker.svg" alt="" />
              <div class="name" v-if="item.hasProfileViewAccess">
                {{ `${item.name} ${item.lastName}` }}
              </div>
              <div class="name" v-else>
                {{ `Jobseeker ${item._id.slice(0, 4)}` }}
              </div>
            </td>
            <td class="actions">
              <div class="action-btns">
                <default-button
                  labelText="Send Job"
                  @click="handleModalDisplay(item._id)"
                />
                <default-button
                  labelText="View profile"
                  @click="goToProfile(item._id)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="matches.length && limit < total">
        <b-pagination
          v-model="currentPage"
          :total-rows="total"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
      <default-modal
        :title="'Send job post to matched job seeker'"
        :confirmLabel="jobsList.length ? 'Send' : 'Close'"
        @onConfirm="handleModalConfirm"
        @onCancel="handleModalCancel"
        id="send-jobs-modal"
        :showCancel="!!jobsList.length"
        :disableConfirmButton="jobsList.length ? !selectedJobs.length : false"
        :description="
          jobsList.length
            ? `Please select one or more existing job posts to send to this matched job seeker.`
            : `You have no active job postings. Please go to the &quot;Job Postings&quot; tab and create a job listing.`
        "
      >
        <template v-slot:input>
          <div class="jobs-list" v-for="job in jobsList" :key="job._id">
            <label :class="job.alreadySent ? 'disabled' : ''">
              <input
                type="checkbox"
                :value="job._id"
                v-model="selectedJobs"
                :disabled="job.alreadySent"
              />
              <span class="checkmark"></span>
              {{ job.name }} {{ job.alreadySent ? "(Already Sent)" : "" }}
            </label>
          </div>
        </template>
      </default-modal>
      <StatusDialog
        :show="showLoading"
        :type="'loading'"
        :heading="'Loading'"
        :description="'Your Data is Loading. Please Wait...'"
      />
    </div>
    <product-purchase
      v-if="product"
      ref="productPurchase"
      :product="product"
      :jobIds="selectedJobs"
      :candidateProfileId="selectedJobseeker"
      :loading="loading"
      :dataRetrieving="dataRetrieving"
      :returnUrlPath="returnUrlPath"
      @set-loading="setLoading"
      @set-data-retrieving="setDataRetrieving"
    >
    </product-purchase>
  </div>
</template>

<script>
import { nanoid } from "nanoid"
import { mapState } from "vuex"
import ProductPurchase from "@/components/Payment/ProductPurchase"
import messagesMixin from "@/mixins/messagesMixin"
import paymentsMixin from "@/mixins/paymentsMixin"
import stripeMixin from "@/mixins/stripeMixin"
import { STRIPE_PRODUCTS } from "@/utils/constants"
import StatusDialog from "@/components/Modals/StatusDialog"

export default {
  name: "company-matches",
  mixins: [messagesMixin, paymentsMixin, stripeMixin],
  components: { ProductPurchase, StatusDialog },
  data() {
    const paymentIntent = this.$router.currentRoute.query?.payment_intent
    return {
      matches: [],
      limit: 20,
      currentPage: 1,
      total: 0,
      columns: ["Job Seeker", "Actions"],
      selectedJobseeker: null,
      selectedJobs: [],
      actionsArray: [
        { name: "Send Job", function: this.handleModalDisplay },
        { name: "View Profile", function: this.goToProfile },
      ],
      product: null,
      productSlug: STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH,
      loading: false,
      dataRetrieving: false,
      returnUrlPath: `/employees/company-matches`,
      backUrlPath: `/employees/company-matches`,
      paymentIntent,
      sentJobPosts: [],
      unlockedApplicants: [],
      showLoading: true,
    }
  },
  methods: {
    async getMatches() {
      const matches = await this.$store.dispatch("api/getCompanyMatches", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.matches = matches.data.results

      for (let match of this.matches) {
        let isUnlockedProfile = this.unlockedApplicants.find(
          (item) => item.profile === match._id
        )

        match.hasProfileViewAccess = !isUnlockedProfile ? false : true
      }

      this.total = matches.data.total
    },
    goToPage(page) {
      this.currentPage = page
    },
    goToProfile(id) {
      this.$router.push("/profile/" + id)
    },
    async handleSendJobsPost() {
      const paymentIntentData = {
        job_ids: this.selectedJobs,
        candidate_profile_id: this.selectedJobseeker,
        product_slug: STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH,
        return_url: this.returnUrlPath,
        idempotencyKey: nanoid(),
      }

      const response = await this.$store.dispatch(
        "api/createPaymentIntent",
        paymentIntentData
      )

      const defaultErrorMessage = "We couldn't submit your payment request."
      if (!response.data || response.data.errors) {
        throw new Error(response.data?.errors ?? defaultErrorMessage)
      }
      const paymentIntent = response.data

      if (paymentIntent) {
        this.$router.push(
          `${this.returnUrlPath}?payment_intent=${paymentIntent.id}&profileId=${this.selectedJobseeker}`
        )
        window.location.reload()
      } else {
        throw new Error(defaultErrorMessage)
      }
    },
    handleModalDisplay(id) {
      this.selectedJobseeker = id
      this.$bvModal.show("send-jobs-modal")
    },
    handleModalCancel() {
      this.selectedJobseeker = null
      this.selectedJobs = []
    },
    handleModalHide() {
      window.location.reload()
    },
    async handleModalConfirm() {
      try {
        if (this.jobsList.length) {
          this.handleSendJobsPost()
        } else {
          this.$bvModal.hide("send-jobs-modal")
        }
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
    setLoading(loading) {
      this.loading = loading
    },
    setDataRetrieving(isRetrieving) {
      this.dataRetrieving = isRetrieving
    },
    async getJobs() {
      await this.$store.dispatch("jobs/dispatchFetchJobs")
    },
    async getProfileAccessDetails() {
      const userId = this.$store.state.user?._id
      const { data } = await this.$store.dispatch("api/getCompanyUserProfile", {
        userId,
      })
      await this.$store.commit("UPDATE_USER_PROFILE", data)

      this.sentJobPosts = data?.provider.sentJobPosts ?? []
      this.unlockedApplicants = data?.unlockedApplicants ?? []
      this.showLoading = false
    },
  },
  computed: {
    ...mapState({
      jobs: ({ jobs }) => JSON.parse(JSON.stringify(jobs.list)),
    }),
    jobsList() {
      let allJobs = this.jobs

      if (!this.selectedJobseeker) {
        return allJobs.map((job) => ({ ...job, alreadySent: false }))
      }

      const sentJobIds = new Set(
        this.sentJobPosts
          .filter((post) => post.profile == this.selectedJobseeker)
          .map((post) => post.job)
      )

      allJobs = allJobs.map((job) => ({
        ...job,
        alreadySent: sentJobIds.has(job._id),
      }))

      return allJobs
    },
  },
  mounted() {
    this.getMatches()

    setTimeout(() => {
      this.getJobs()
      this.getProfileAccessDetails()
    }, 3000)
  },
  watch: {
    currentPage() {
      this.getMatches()
    },
  },
  async created() {
    const product = await this.getProduct(this.productSlug)
    this.product = product
    this.dataRetrieving = true
  },
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

table {
  background: var(--Gray-100, #eceef2);
  border: none;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid var(--Gray-200, #d5d9e2);

  thead {
    th {
      color: var(--Gray-900, #343946);
      font-size: 16px;
      letter-spacing: 0;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
      border-bottom: 1px solid var(--Gray-300, #b0b9c9);

      &:last-of-type {
        text-align: center;
      }
    }
  }

  tbody {
    display: block;
    max-height: calc(100vh - 250px);
    overflow: auto;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody tr {
    border-bottom: 1px solid var(--Gray-200, #d5d9e2);

    &:first-of-type {
      border-top: 1px solid var(--Gray-300, #b0b9c9);
    }

    &:last-of-type {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    td {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;

      img {
        margin-right: 16px;
      }

      &.actions {
        text-align: center;
        text-align: -webkit-center;

        .action-btns {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.name-column {
        display: flex;
        align-items: center;
        .name {
          font-weight: 600;
          display: flex;
          flex-direction: column;

          span {
            color: var(--Gray-500, #677690);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }

    &:last-child td:first-child {
      border-bottom-left-radius: 12px;
    }
    &:last-child td:last-child {
      border-bottom-right-radius: 12px;
    }

    &:first-child td {
      border-top-style: solid;
    }
  }

  tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  tbody tr:nth-of-type(even) {
    background-color: var(--Gray-50, #f6f7f9);
  }
}

.jobs-list {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.jobs-list input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
}

.jobs-list label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;

  &.disabled {
    color: #b0b9c9;
    cursor: not-allowed;
    font-weight: 500;
  }
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #b0b9c9;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}

.jobs-list input:checked + .checkmark {
  background-color: #0095e2;
  border-color: #0095e2;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.jobs-list input:checked + .checkmark::after {
  display: block;
}

.jobs-list .checkmark::after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
</style>
