var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',[_c('p',{staticClass:"info-header"},[_vm._v(" This is where you’ll see all of the jobs you’ve saved. These could be jobs that you’ve found via search or jobs that have been sent to you. ")]),(_vm.jobs.length)?_c('table',{staticClass:"table table-striped table-hover jobs-table jobs-list"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column},[_vm._v(_vm._s(column))])}),0)]),_c('tbody',_vm._l((_vm.jobs),function(item,index){return _c('tr',{key:index},[_c('td',[(
                item.company[0].profilePhoto &&
                typeof item.company[0].profilePhoto === 'object'
              )?_c('signed-image',{attrs:{"file":item.company[0].profilePhoto,"alt":item.company[0].name}}):_c('img',{attrs:{"src":"/img/icons/default-company.svg","alt":""}}),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
                  name: 'profile-id',
                  params: {
                    profileId: item.profile[0]._id,
                    type: 'provider',
                  },
                })}}},[_vm._v(" "+_vm._s(item.profile[0].name)+" ")])],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"status"},[_vm._v(_vm._s(item.city)+", "+_vm._s(item.state))]),_c('td',[_vm._v(_vm._s(item.compensation))]),_c('td',{staticClass:"action-data"},[_c('default-button',{attrs:{"btnType":['unsave-btn', 'saved-jobs-btn'],"labelText":"Unsave"},on:{"click":function($event){return _vm.handleSaveJob(item._id)}}}),_c('default-button',{attrs:{"btnType":['saved-jobs-btn'],"labelText":"View Job"},on:{"click":function($event){return _vm.$router.push(("/jobs/" + (item._id)))}}})],1)])}),0)]):_vm._e(),(_vm.jobs.length)?_c('div',{staticClass:"jobs-list-mobile mobile-cards-wrapper"},_vm._l((_vm.jobs),function(item,index){return _c('div',{key:index,staticClass:"activity-wrapper mobile-card"},[_vm._m(0,true),_c('div',{staticClass:"company-name",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'profile-id',
              params: { profileId: item.profile[0]._id, type: 'provider' },
            })}}},[_vm._v(" "+_vm._s(item.profile[0].name)+" ")]),_c('div',{staticClass:"role-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"dates-wrapper"},[_c('div',{staticClass:"date"},[_c('span',{staticClass:"date-header"},[_vm._v("Location")]),_vm._v(" "+_vm._s(item.city)+", "+_vm._s(item.state)+" ")]),_c('div',{staticClass:"date"},[_c('span',{staticClass:"date-header"},[_vm._v("Compensation")]),_vm._v(" "+_vm._s(item.compensation)+" ")])]),_c('div',{staticClass:"btns-wrapper"},[_c('div',{staticClass:"btn view-btn"},[_c('a',{on:{"click":function($event){return _vm.$router.push(("/jobs/" + (item._id)))}}},[_vm._v("View Job")])]),_c('div',{staticClass:"btn"},[_c('a',{on:{"click":function($event){return _vm.handleSaveJob(item._id)}}},[_vm._v("Remove From List")])])])])}),0):_vm._e(),(!_vm.jobs.length)?_c('div',{staticClass:"empty-data"},[_c('p',[_vm._v("No data available.")])]):_vm._e(),(_vm.limit < _vm.total)?_c('div',{staticClass:"pagination"},[_c('b-pagination',{attrs:{"total-rows":_vm.total,"per-page":_vm.limit},on:{"click":_vm.goToPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()]),_c('toast-message',{ref:"toastMessage"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":"/img/icons/default-company.svg","alt":""}})])}]

export { render, staticRenderFns }