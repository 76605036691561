<template>
  <div class="opportunities-wrapper">
    <div class="header">
      <span class="heading">My Opportunities</span>
      <div
        class="edit-btn"
        @click="$router.push('/my-jobs/activities')"
        v-if="opportunities.length"
      >
        View All →
      </div>
    </div>
    <template v-if="opportunities.length">
      <table class="table table-striped table-hover job-opportunities">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in opportunities" :key="index">
            <td>
              <img src="/img/icons/default-company.svg" alt="" />
              <span
                @click.stop="
                  $router.push({
                    name: 'profile-id',
                    params: {
                      profileId: item.company[0]._id,
                      type: 'provider',
                    },
                  })
                "
              >
                {{ item.company[0].name }}
              </span>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ getFormattedDate(item.createdAt) }}</td>
            <td>Jun 6 at 3:45 PM</td>
            <td @click="$router.push(`/jobs/${item._id}`)" class="job-link">
              View job post
            </td>
          </tr>
        </tbody>
      </table>
      <div class="job-opportunities-mobile mobile-cards-wrapper">
        <div
          v-for="(item, index) in opportunities"
          :key="index"
          class="opportunity-wrapper mobile-card"
        >
          <div class="img">
            <img src="/img/icons/default-company.svg" alt="" />
          </div>
          <div class="company-name">
            {{ item.company[0].name }}
          </div>
          <div class="role-name">
            {{ item.name }}
          </div>
          <div class="dates-wrapper">
            <div class="date">
              <span class="date-header">Date Received</span>
              {{ getFormattedDate(item.createdAt) }}
            </div>
            <div class="date">
              <span class="date-header">Expiration</span>
              Jun 6 at 3:45 PM
            </div>
          </div>
          <div class="btn">
            <a @click="$router.push(`/jobs/${item._id}`)">View Job Post</a>
          </div>
        </div>
      </div>
    </template>
    <div class="no-data" v-else>
      <img src="/img/icons/no-data.svg" alt="" />
      <div class="info">No opportunities at this time</div>
      <div class="additional-info">
        Check back later, or try searching for a job within the
        <span class="link" @click="$router.push(`/jobs/search`)"
          >Find Jobs</span
        >
        page.
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment"
export default {
  name: "job-opportunities",
  props: {
    opportunities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: ["Company", "Role", "Date Received", "Expiration", ""],
    }
  },
  methods: {
    getFormattedDate(dateString) {
      const parsedDate = moment(dateString)

      const currentDate = moment()

      const diffInDays = currentDate.diff(parsedDate, "days")

      // Determine the display format based on the difference in days
      let displayDate
      if (diffInDays === 0) {
        displayDate = "Today at " + parsedDate.format("h:mm A")
      } else if (diffInDays === 1) {
        displayDate = "Yesterday at " + parsedDate.format("h:mm A")
      } else {
        displayDate = parsedDate.format("DD MMM YYYY [at] hh:mm A")
      }
      return displayDate
    },
  },
}
</script>
<style lang="scss">
.opportunities-wrapper {
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .heading {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
    }

    .edit-btn {
      display: flex;
      padding: 10px 24px 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 2px solid var(--gfb-blue-brand-500, #0095e2);
      background: var(--White, #fff);
      font-size: 14px;
      color: var(--gfb-blue-brand-500, #0095e2);
      font-weight: 600;
      cursor: pointer;
    }
  }

  table {
    border-radius: 18px;
    background: var(--Gray-100, #eceef2);
    padding: 16px 8px 8px 8px;
    border: none;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;

    thead {
      th {
        color: var(--Gray-900, #343946);
        font-size: 16px;
        letter-spacing: 0;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
      }
    }

    tbody tr {
      border-bottom: 1px solid var(--Gray-200, #d5d9e2);

      &:last-child {
        border-bottom: unset;
      }

      td {
        color: var(--Gray-800, #3a4252);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        vertical-align: middle;
        word-wrap: break-word;
        white-space: normal;

        img {
          margin-right: 16px;
        }

        &.job-link {
          color: var(--gfb-blue-brand-500, #0095e2);
          cursor: pointer;
        }
      }

      &:first-child td:first-child {
        border-top-left-radius: 12px;
      }
      &:first-child td:last-child {
        border-top-right-radius: 12px;
      }

      &:last-child td:first-child {
        border-bottom-left-radius: 12px;
      }
      &:last-child td:last-child {
        border-bottom-right-radius: 12px;
      }

      &:first-child td {
        border-top-style: solid;
      }
    }

    tbody tr:nth-of-type(odd) {
      background-color: #fff;
    }

    tbody tr:nth-of-type(even) {
      background-color: var(--Gray-50, #f6f7f9);
    }
  }

  .no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 112px;

    img {
      width: 250px;
    }

    .info {
      color: var(--Gray-900, #343946);
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      padding-top: 24px;
      padding-bottom: 8px;
    }

    .additional-info {
      color: var(--Gray-600, #525e77);
      text-align: center;
      font-size: 16px;
      font-weight: 400;

      .link {
        color: var(--gfb-blue-brand-500, #0095e2);
        font-weight: 600;
      }
    }
  }
}

.job-opportunities-mobile {
  display: none;
}

@media (max-width: 576px) {
  .edit-btn {
    font-size: 8px !important;
  }

  .heading {
    font-size: 17px !important;
  }
}

@media (max-width: 992px) {
  .job-opportunities {
    display: none;
  }

  .job-opportunities-mobile {
    display: flex;
  }
}
</style>
