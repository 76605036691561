<template>
  <div class="info-section">
    <p class="info-warning-text">
      Please answer all questions truthfully — businesses will question your
      expertise in interviews.
    </p>
    <p class="info-warning-text">
      Your profile photo, contact info, and past employers are kept private
      until you apply for a job.
    </p>
    <p class="required-indication">* - indicates required field</p>
  </div>
</template>

<script>
export default {
  name: "InfoWarningSection",
}
</script>

<style scoped>
.required-indication {
  font-weight: bold;
  font-style: italic;
}
</style>
