<template>
  <img v-if="typeof signedUrl === 'string'" :src="signedUrl" />
</template>

<script>
import file from "@/api/file.js"
export default {
  name: "signed-image",
  props: {
    file: {
      type: [String, Object],
    },
  },
  data() {
    return {
      signedUrl: this.file,
    }
  },
  async mounted() {
    if (this.file) {
      try {
        /*
        const { data } = await this.$store.dispatch("api/getFileUrl", {
          name: this.file.name,
          version: this.file.version,
          profileId: this.file.profile,
        })
        */
        if (this.file.id) {
          const { data } = await file.getFileUrl({ id: this.file.id })
          if (data.errors) {
            console.error(data.errors[0])
          } else {
            const signedUrl = data.url
            if (signedUrl) {
              this.signedUrl = signedUrl
            }
          }
        }
      } catch (error) {
        if (process.env.NODE_ENV === "development") {
          console.error(error)
        }
      }
    }
  },
  watch: {
    file: {
      handler() {
        this.signedUrl = this.file
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-pic {
  max-height: 100px;
  max-width: 100px;
  border-radius: unset !important;
}
</style>
